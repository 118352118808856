<template>
  <div class="pc-group-checkbox-container">
    <div class="title-container">
      <div
        class="title-open"
        :style="{ color: isOpen ? '#a8221a' : 'rgba(48, 48, 48, 1)' }"
      >
        {{ title }}
      </div>
    </div>
    <div class="checkbox-container">
      <div v-for="filterItem in list" v-bind:key="filterItem.key">
        <label class="filter-checkbox item">
          <input
            type="checkbox"
            :name="filterItem.name"
            :value="filterItem.name"
            v-model="filterItem.select"
          />
          <span>{{ filterItem.name }}</span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
    list: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  data() {
    return {
      images: {
        closeIcon: require('@/assets/images/icon/accordion-close.png'),
        openIcon: require('@/assets/images/icon/accordion-open.png'),
      },
    };
  },
};
</script>
<style scoped lang="scss">
  @import '@/assets/scss/variables';
.pc-group-checkbox-container {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  margin-bottom: 14px;

  .none {
    display: none;
  }

  .winex-dropdown {
    cursor: pointer;
    input[type='checkbox'] {
      display: none !important;
    }
    input[type='checkbox'] {
      font-size: 16px;
      height: 28px;
      line-height: 28px;
      display: inline-block;
      padding-left: 34px;
      background-repeat: no-repeat;
      background-image: url(~@/assets/images/icon/accordion-open.png);
    }
    input[type='checkbox']:checked {
      background-image: url(~@/assets/images/icon/accordion-close.png);
    }
  }

  .filter-checkbox {
    cursor: pointer;
    margin-bottom: 0;
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox'] ~ span {
      font-size: 16px;
      display: inline-block;
      padding-left: 8px;
      padding-right: 8px;
      padding-top: 4px;
      padding-bottom: 4px;

      width: max-content;
      border: 1px solid $primary;
      background-color: transparent;
      border-radius: 40px;
      color: $primary;
    }
    input[type='checkbox']:checked ~ span {
      background-color: $primary;
      color: #fff;
    }
  }

  .title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    .title-open {
      font-family: 'SUIT';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
    }

    .dropdown-button {
      cursor: pointer;
    }
  }

  .checkbox-container {
    display: flex;
    overflow-x: auto;
    width: 100%;
    max-width: 100%;
    padding-right: 40px;

    .item {
      margin-bottom: 12px;
      margin-right: 12px;
    }
  }

  .checkbox-container::-webkit-scrollbar {
    height: 5px;
  }
  .checkbox-container::-webkit-scrollbar-track {
    display: none;
  }
  .checkbox-container::-webkit-scrollbar-thumb {
    background-color: $light_light_dark;
    border-radius: 20px;
  }
  .checkbox-container::-webkit-scrollbar-thumb:hover {
    background-color: $light_dark;
  }
}
</style>
